import React from 'react'
import axios from "axios";
import DatePicker from "react-datepicker";

import { getToken } from "../../../utils/utils";
import { useState } from "react";
import { EXPENSE_CATEGORIES, PAYMENT_METHOD } from '../../../constants/sharedConstans';
import { ENDPOINT } from '../../../constants/endpointConstants';

const NewExpense = ({ pushNewExpense }) => {

    const [date, setDate] = useState(new Date())
    const [expenseCategoryId, setExpenseCategoryId] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState('1');
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const[errorMsg, setErrorMsg] = useState('');

    const priceChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setPrice(0)
        } else {
            setPrice(parseInt(value))
        }
    }

    const descriptionChange = (event) => {
        setDescription(event.target.value);
    }


    const datePickerOnChange = (update) => {
        setDate(update)
    }

    const buttonContent = () => {
        if(loading === false){
            return 'Agregar';
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const addNewExpense = async () => {
        let newExpense = {
            description,
            date: date.getTime(),
            category: parseInt(expenseCategoryId),
            paymentType: paymentMethodId,
            price,
            invoice: imageSrc
        }
        const token = getToken()
        setLoading(true)
        try {
            const submitExpense = await axios.post(ENDPOINT.NEW_EXPENSES,newExpense,token)
            setLoading(false)
            pushNewExpense(submitExpense)
            resetNewExpense();
        } catch (error) {
            setLoading(false)
        }
    } 

    const resetNewExpense = () => {
        setDate(new Date());
        setDescription('');
        setExpenseCategoryId('')
        setPrice('');
        setPaymentMethodId('1')
        setImageSrc(null)
    } 

    const expenseCategoryChange = (event) => {
        setExpenseCategoryId(event.target.value);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
            const imageDataUrl = reader.result;
            setImageSrc(imageDataUrl);
            };
            reader.readAsDataURL(file);
        }
    };

    const isButtonDisabled = () =>{
        if (errorMsg) {
            return true;
        } else if(!description){
            return true;
        } else if(!expenseCategoryId){
            return true;
        } else if(!imageSrc){
            return true;
        } else if(!price){
            return true;
        } else{
            return false;
        }
    }

    const paymentMethodChange = (event) => {
        setPaymentMethodId(event.target.value)
    }

    return (
        <div>
            <div className='row'>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Fecha</label>
                    <div>
                        <DatePicker
                            key={'new-expense-date-picker'}
                            selected={date}
                            dateFormat="yyyy/MM/dd"
                            onChange={datePickerOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Descripcion</label>
                    <textarea type="text" className="form-control" value={description} onChange={descriptionChange}/>
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Categoria</label>
                    <select className="form-select" value={expenseCategoryId} onChange={expenseCategoryChange}>
                        <option value="" disabled>Seleccione una categoria</option>
                        {EXPENSE_CATEGORIES.map((expense, i) => <option value={expense.id} key={i}> {expense.name} </option>)}
                    </select>
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Factura</label>
                    <div className="d-flex">
                        <label className="take-photo take-photo d-flex flex-column align-items-center justify-center" htmlFor="upload-photo">
                            <i className="fa-solid fa-camera"></i>
                            <span>Tomar foto</span>
                        </label>
                        <input className="d-none" type="file" accept="image/*" id="upload-photo" onChange={handleFileChange}/>
                        {imageSrc && <img className="expense-preview ms-5" src={imageSrc}/>}
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Tipo de pago</label>
                    <select className="form-select" value={paymentMethodId} onChange={paymentMethodChange}>
                        {PAYMENT_METHOD.map((method, i) => <option value={method.id} key={i}> {method.name} </option>)}
                    </select>
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Valor</label>
                    <input type="number" className="form-control" placeholder="Valor" value={price === 0 ? "" : price} onChange={priceChange} />
                </div>
            </div>
            {errorMsg && 
                <div className="row mt-4">
                    <p className="error-msg">{errorMsg}</p>
                </div>
            }
            <div className="row justify-content-center mt-5">
                <div className="col-6 col-md-5">
                    <button type="button" className="btn btn-primary btn-sm w-100 text-modal" onClick={addNewExpense} disabled={isButtonDisabled()}> {buttonContent()}</button>
                </div>
            </div>
        </div>
    )
}

export default NewExpense