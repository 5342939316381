const env = process.env.NODE_ENV;
const api_urls = {
    // local: 'http://localhost:9090',
    local: 'https://86ex7jl7r4.execute-api.us-west-2.amazonaws.com',
    prod: 'https://86ex7jl7r4.execute-api.us-west-2.amazonaws.com'
}

export const ENDPOINT = {
    POST_LOGIN: `${api_urls[env] || api_urls.local || api_urls.local}/dev/user/login`,
    POST_NEW_PRODUCT: `${api_urls[env] || api_urls.local || api_urls.local}/dev/product`,
    POST_SALES_BY_DATES: `${api_urls[env] || api_urls.local}/dev/sale-by-dates`,
    GET_PRODUCTS: `${api_urls[env] || api_urls.local}/dev/product`,
    PUT_UPDATE_PRODUCT: `${api_urls[env] || api_urls.local}/dev/product`,
    DEL_DELETE_PRODUCT: `${api_urls[env] || api_urls.local}/dev/product`,
    POST_NEW_SUPPLY: `${api_urls[env] || api_urls.local || api_urls.local}/dev/supply`,
    GET_SUPPLIES: `${api_urls[env] || api_urls.local}/dev/supply`,
    PUT_UPDATE_SUPPLY: `${api_urls[env] || api_urls.local}/dev/supply`,
    DEL_DELETE_SUPPLY: `${api_urls[env] || api_urls.local}/dev/supply`,
    NEW_SALE: `${api_urls[env] || api_urls.local}/dev/sale`,
    OPEN_SALES: `${api_urls[env] || api_urls.local}/dev/sales-open`,
    UPDATE_SALE: `${api_urls[env] || api_urls.local}/dev/update-sale`,
    POST_PAY_SALE: `${api_urls[env] || api_urls.local}/dev/pay-open-sale`,
    GET_PENDING_SALES: `${api_urls[env] || api_urls.local}/dev/sales-pending`,
    CLOSE_PENDING_SALES: `${api_urls[env] || api_urls.local}/dev/close-sales-pending`,
    GET_EXPENSES: `${api_urls[env] || api_urls.local}/dev/expenses`,
    NEW_EXPENSES: `${api_urls[env] || api_urls.local}/dev/expenses`,
    DELETE_EXPENSES: `${api_urls[env] || api_urls.local}/dev/expenses`,
}