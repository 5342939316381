import React from 'react'

const Invoice = React.forwardRef(({products}, ref) => {
  return (
    <div ref={ref} className='invoice'>
      <ul>
        {
          products?.length &&
          products.map((product, index) => <li key={index}><span>{product.productName}: </span>{product.comment}</li>)
        }
      </ul>
    </div>
  )
})

export default Invoice