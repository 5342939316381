import axios from "axios";
import NewExpense from './components/NewExpense'
import React from 'react'
import Table from "../../components/Table";
import DatePicker from "react-datepicker";
import ChartLabels from 'chartjs-plugin-datalabels';
import { useState, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { ENDPOINT } from "../../constants/endpointConstants";
import { getToken, getRangeDates } from "../../utils/utils";
import { EXPENSE_CATEGORIES } from "../../constants/sharedConstans";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, ChartLabels, Tooltip, Legend);
const ExpensesPage = () => {
    const [showNewExpense, setShowNewExpense] = useState(false);
    const [showModalInvoice, setShowModalInvoice] = useState(false);
    const [invoiceSelected, setInvoiceSelected] = useState();
    const [expenses, setExpenses] = useState([]);
    const [isLoadingExpenses, setIsLoadingExpenses] = useState(false)
    const [dateRange, setDateRange] = useState(getRangeDates(new Date(), new Date()));
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [chartData, setChartData] = useState();
    const [startDate, endDate] = dateRange;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [expenseToDelete, setExpenseToDelete] = useState();

    

    const buildChartData = (expenses) => {
        const data = {
            labels: EXPENSE_CATEGORIES.map(category => category.name),
            datasets: [
              {
                label: 'Total ventas',
                data: EXPENSE_CATEGORIES.map(category => 
                    expenses
                        .filter(expense => expense.category === category.id)
                        .reduce((acc, exp) => acc + exp.price, 0)
                ),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',   // Light Red
                    'rgba(75, 192, 192, 0.2)',   // Light Green
                    'rgba(54, 162, 235, 0.2)',   // Light Blue
                    'rgba(255, 206, 86, 0.2)',   // Light Orange
                    'rgba(153, 102, 255, 0.2)',  // Light Indigo
                    'rgba(238, 130, 238, 0.2)',  // Light Violet
                    'rgba(255, 223, 186, 0.2)',  // Light Gold
                    'rgba(0, 255, 255, 0.2)',    // Light Cyan
                    'rgba(255, 182, 193, 0.2)',  // Light Pink
                    'rgba(135, 206, 235, 0.2)'   // Light Navy
                  ],
                borderColor: [
                    'rgba(255, 0, 0, 1)',      // Red
                    'rgba(0, 128, 0, 1)',      // Green
                    'rgba(0, 0, 255, 1)',      // Blue
                    'rgba(255, 165, 0, 1)',    // Orange
                    'rgba(75, 0, 130, 1)',     // Indigo
                    'rgba(238, 130, 238, 1)',  // Violet
                    'rgba(255, 215, 0, 1)',    // Gold
                    'rgba(0, 255, 255, 1)',    // Cyan
                    'rgba(255, 20, 147, 1)',   // Deep Pink
                    'rgba(0, 0, 128, 1)'       // Navy
                  ],
                borderWidth: 1,
              },
            ],
        };
        setChartData(data);
    }

    const getExpensesData = async (startDate, endDate) => {
        const token = getToken()
        const options = {
            ...token, 
            params: {
                startDate,
                endDate
            }
        }
        try {
            setIsLoadingExpenses(true);
            const {data} = await axios.get(ENDPOINT.GET_EXPENSES,options)
            buildChartData(data.expenses);
            setExpenses(data.expenses);
            setTotalExpenses(data.total);
            setIsLoadingExpenses(false);
        } catch (error) {
            setIsLoadingExpenses(false);
        }
    }


    const deleteExpenseCall = async () => {
        const token = getToken()
        try {
            setIsLoadingExpenses(true);
            const {data} = await axios.delete(`${ENDPOINT.DELETE_EXPENSES}/${expenseToDelete}`,{...token});
            setIsLoadingExpenses(false);
            setExpenseToDelete(undefined);
            modalCloseDeleteConfirmation();
            getExpensesData(dateRange[0].getTime(),dateRange[1].getTime())

        } catch (error) {
            setIsLoadingExpenses(false);
        }
    }

    useEffect( () => {
        getExpensesData(dateRange[0].getTime(),dateRange[1].getTime());
    }, [])

    const showInvoice = (invoice) => {
        setInvoiceSelected(invoice)
        setShowModalInvoice(true)
    }

    const newExpense = ({data}) => {
        getExpensesData(dateRange[0].getTime(),dateRange[1].getTime())
        modalClose();
    }

    const deleteExpense = ({expenseId}) => {
        setExpenseToDelete(expenseId);
        setShowDeleteConfirmation(true);
    }

    const expensesColumns = [
        {
            name: "Fecha",
            key: "date",
            colSize:'col-3',
            type: "date"
        },
        {
            name: "Descripcion",
            key: "description",
            colSize:'col-2',
            type: "text"
        },
        {
            name: "Precio",
            key: "price",
            colSize:'col-1',
            type: "currency"
        },
        {
            name: "Categoria",
            key: "category",
            type: "map",
            colSize:'col-2',
            map: EXPENSE_CATEGORIES
        },
        {
            name: "Metodo Pago",
            key: "paymentType",
            colSize:'col-2',
            type: "paymentMethod"
        },
        {
            name: "Acciones",
            type: "actions",
            colSize:'col-2',
            buttons: [
                {
                    iconClass: "fa-solid fa-file-invoice-dollar",
                    action: showInvoice 
                },
                {
                    iconClass: "fa-solid fa-trash",
                    action: deleteExpense 
                }
            ]
        }
        
    ];

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            labels: {
                render: 'percentage',
                fontColor: ['green', 'white', 'red'],
                precision: 2
              },
              legend: {
                position: 'bottom',
                labels: {
                    filter: (legendItem, chartData) => {
                      const dataIndex = legendItem.index;
                      const value = chartData.datasets[0].data[dataIndex];
                      return value !== 0;
                    },
                  }
              },
              datalabels: {
                formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return percentage > 0 ? `${percentage}%` : '';
                  },
                color: '#000',
                font: {
                  weight: 'bold',
                },
              }
          }
    };

    const showModal = () => {
        setShowNewExpense(true)
    } 

    const modalClose = () => {
        setShowNewExpense(false)
    }

    const modalCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false)
    }

    const modalCloseInvoice = () => {
        setShowModalInvoice(false)
    }

    const showModalNewExpense = () => {
        showModal()
    }

    const datePickerOnChange = (update) => {
        const [dateInit, dateFinish] = getRangeDates(update[0], update[1]);
        setDateRange([dateInit, dateFinish])
        if(update[0] && update[1]){
            getExpensesData(dateInit.getTime(),dateFinish.getTime()) 
        } 
    }

    const spinnerDelete = () => {
        if(isLoadingExpenses === false){
            return "SI";
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    return (
        <div>
            <section>
                <div className ="mx-4">
                    <h1 className="style-title-page mt-4">Gastos</h1>
                    <div className="row">
                        <div className="col-md-9 col-12 mb-4">
                            <p className="mt-4 mb-4"> Selecciona la fecha o el rango de fechas a consultar </p>
                            <div className="row">
                                <div className="col-md-3 col-8">
                                    <DatePicker
                                        key={'expense-date-picker'}
                                        selectsRange={true}
                                        startDate={startDate}
                                        dateFormat="yyyy/MM/dd"
                                        endDate={endDate}
                                        onChange={datePickerOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="row mt-md-0 mt-3">
                                <div className="col-md-12 col-6">
                                    <div className="style-cards" >
                                        <i className="fa-solid fa-file-invoice-dollar style-icon-sale"></i>
                                        <div className="d-flex  flex-column">
                                            <p className="bold"> Total </p>
                                            <p > { "$" + new Intl.NumberFormat('es-CL').format(totalExpenses)} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-lg-4">
                            { expenses.length > 0 && <Pie data={chartData} options={options}/>}
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="d-flex justify-content-end mb-3 mt-5 mt-sm-0">
                                <button type="button" className="btn btn-primary btn-sm new-expenses-btn" data-bs-target="#modal" onClick={showModalNewExpense}> <i className="fa-solid fa-plus"></i> Nuevo Gasto </button>
                            </div>
                            <Table columnNames={expensesColumns} items={expenses} fixSize={'t-responsive-large'} isLoading={isLoadingExpenses}/>
                        </div>
                    </div>
                    
                </div>
            </section>
            <Modal show={showNewExpense} onHide={modalClose}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title className="text-modal"> Gasto </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewExpense pushNewExpense={newExpense} />
                </Modal.Body>
            </Modal>
            <Modal show={showModalInvoice} onHide={modalCloseInvoice}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title className="text-modal"> Factura </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="invoice-img" src={`https://que-bendicion-invoices.s3.us-west-2.amazonaws.com/${invoiceSelected?.expenseId}.${invoiceSelected?.imgType}`}/>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteConfirmation} onHide={modalCloseDeleteConfirmation}>
                <Modal.Header closeButton className="style-modal-header ">
                    <Modal.Title className="text-modal"> ELIMINAR GASTO </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <p className="text-center"> Estas seguro que deseas eliminar este gasto? </p>
                    <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={deleteExpenseCall}> {spinnerDelete()} </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={modalCloseDeleteConfirmation}> NO </button>
                            </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ExpensesPage