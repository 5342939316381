import { useState } from "react";
import Table from "../../components/Table";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { ENDPOINT } from "../../constants/endpointConstants";
import axios from "axios";
import { useEffect } from "react";
import { getToken } from "../../utils/utils";

const InventoryPage = () => {
    const[newSupply, setNewSupply] = useState(false);
    const[deleteSupplies, setDeleteSupplies] = useState(false)
    const[items, setItems] = useState([]);
    const[nameSupply, setNameSupply] = useState('');
    const[loading, setLoading] = useState(false);
    const[supplyAmount, setSupplyAmount] = useState('');
    const[isUpdateModal, setIsUpdateModal] = useState(false);
    const[supplyId, setSupplyId] = useState('');
    const[isLoadingInventory, setIsLoadingInventory] = useState(false);



    const bringInformation = async () => {
        const token = getToken();
        setIsLoadingInventory(true);
        const bringSupplies = await axios.get(ENDPOINT.GET_SUPPLIES, token);
        setItems(bringSupplies.data);
        setIsLoadingInventory(false);
    }
    useEffect( () => {
        bringInformation();
    } ,[]) 

    const addNewSupply = async () => {
        let addSupply = {
            name: nameSupply,
            amount: supplyAmount,
        }
        const token = getToken()
        setLoading(true)
        try {
            const submitSupply = await axios.post(ENDPOINT.POST_NEW_SUPPLY,addSupply,token)
            setLoading(false)
            let newItems = items;
            newItems.push(submitSupply.data)
            setItems(newItems.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)))
            modalClose();
            resetSupplyFormValues();
        } catch (error) {
            setLoading(false)
        }
    } 

    const updateSupply = async () => {
        let update = {
            name: nameSupply,
            amount: supplyAmount,
            supplyId: supplyId,
        }
        const token = getToken()
        setLoading(true)
        try {
            const submitUpdateSupply = await axios.put(ENDPOINT.PUT_UPDATE_SUPPLY,update,token)
            setLoading(false)
            const supplyIndex = items.findIndex( (item) => {
                return item.supplyId === supplyId
            })
            items[supplyIndex] = submitUpdateSupply.data
            setItems(items)
            modalClose();
            resetSupplyFormValues();
        } catch (error) {
            setLoading(false)
            
        }
    }

    const deleteSupplyModal = async () => {
        let putOffSupply = {
            supplyId: supplyId,
        }
        const token = getToken()
        token.data = putOffSupply
        setLoading(true)
        try {
            const submitDeleteSupply = await axios.delete(ENDPOINT.DEL_DELETE_SUPPLY,token)
            setLoading(false)
            const itemsUpdated = items.filter( (item) => {
                return item.supplyId !== supplyId
            })
            setItems(itemsUpdated)
            modalCloseDelete()
        } catch (error) {
            setLoading(false)
        }
    }


    const nameChange = (event) => {
        setNameSupply(event.target.value)
    }

    const supplyAmountChange = (event) => {
        setSupplyAmount(parseInt(event.target.value))
    }

    const showModalDelete = () => {
        setDeleteSupplies(true)
    }

    const showModal = () => {
        setNewSupply(true)
    }

    const modalCloseDelete = () => {
        setDeleteSupplies(false)
    }
    
    const modalClose = () => {
        setNewSupply(false)
    }
    const spinnerDelete = () => {
        if(loading === false){
            return "SI";
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const buttonContent = () => {
        if(loading === false){
            return UpdateModal();
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const isButtonDisabled = () => {
        if( nameSupply === "" ||
            isValidInputNumber(supplyAmount)){
            return true
        } else {
            return false
        }
    }

    const isValidInputNumber =  (value) => {
        return !value || isNaN(value);
    }

    const resetSupplyFormValues = () => {
        setNameSupply('');
        setSupplyAmount('');
    }    

    const editSupply = (supply) => {
        setNameSupply(supply.name)
        setSupplyAmount(supply.amount)
        setSupplyId(supply.supplyId)
        setIsUpdateModal(true)
        showModal()
    }

    const deleteSupply = (supply) => {
        setSupplyId(supply.supplyId)
        showModalDelete()
    }

    const UpdateModal = () => {
        if(isUpdateModal === true){
            return (
                "ACTUALIZAR"
            ) 
        } else {
            return (
                "AGREGAR"
            )
        }
    }

    const modalOnClick = () => {
        if(isUpdateModal === true){
            updateSupply()

        } else {
            addNewSupply()
        }
    }

    const showModalNewSupply = () => {
        resetSupplyFormValues()
        setIsUpdateModal(false)
        showModal()
    }

    const inventoryColumns = [
        {
            name: "Nombre Ingrediente",
            key: "name",
            type: "text"
        },
        {
            name: "Cantidad",
            key: "amount",
            type: "number"
        },
        {
            name: "Acciones",
            key:"action",
            type: "actions",
            buttons: [
                {
                    iconClass: "fa-regular fa-pen-to-square",
                    action: editSupply 
                },
                {
                    iconClass: "fa-solid fa-trash-can",
                    action: deleteSupply
                }
            ]
        }

    ]


    return(
        <div>
            <div className ="mx-4">
                <h1 className="style-title-page mt-4">Inventario</h1>
                <button type="button" className="btn btn-primary btn-sm mt-5" data-bs-target="#modal" onClick={showModalNewSupply}> <i className="fa-solid fa-plus"></i> Agregar ingrediente </button>
                <Table columnNames={inventoryColumns} items={items} fixSize={'t-responsive-large'} isLoading={isLoadingInventory}/>
            </div>
            <Modal show={newSupply} onHide={modalClose}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title className="text-modal"> {UpdateModal()} INGREDIENTE </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="col-form-label">Nombre Ingrediente</label>
                            <input type="text" className="form-control" value={nameSupply} onChange={nameChange}/>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Cantidad</label>
                            <input type="number" className="form-control" id="sale-amount" value={supplyAmount} onChange={supplyAmountChange}/>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-6 col-md-5">
                            <button type="button" className="btn btn-primary btn-sm w-100 text-modal" onClick={modalOnClick} disabled={isButtonDisabled()}> {buttonContent()}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={deleteSupplies} onHide={modalCloseDelete}>
                <Modal.Header closeButton className="style-modal-header ">
                    <Modal.Title className="text-modal"> ELIMINAR INGREDIENTE </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <p className="text-center"> Estas seguro que deseas eliminar este ingrediente? </p>
                    <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={deleteSupplyModal}> {spinnerDelete()} </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={modalCloseDelete}> NO </button>
                            </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default InventoryPage;