import React, { useContext, useState } from "react";
import logo from "../../assets/logo-bendi.png";
import {useNavigate,useLocation, NavLink} from "react-router-dom";
import { AuthorizationContext } from '../../AuthorizationProvider'


const NavBar = ({name}) => {
    const { userRole } = useContext(AuthorizationContext);
    const [showMenu, setShowMenu] = useState(false)

    const navigate = useNavigate ();

    let location = useLocation();

    const logout = () => {
        localStorage.removeItem("userToken")
        navigate("")
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const showNavBar = () => {
        if(location.pathname === "/"){
            return ""
        } else {
            return (
                <nav className="navbar navbar-expand-lg style-nav">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center">
                            <img className="navbar-brand style-nav-logo" src= {logo}/>
                            <p className="style-name"> {name} </p>
                        </div>
                        <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={toggleMenu}>
                            <i className="fa-solid fa-bars" />
                        </button>
                        <div className={`collapse navbar-collapse justify-content-end ${showMenu ? 'show' : ''}`} id="navbarNavAltMarkup">   
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className= "nav-link style-nav-link" to="/caja" onClick={toggleMenu}>CAJA </NavLink>
                                </li>
                                
                                { userRole !== 0 && <li className="nav-item">
                                    <NavLink className="nav-link style-nav-link" to="/platos" onClick={toggleMenu}>PLATOS </NavLink>
                                </li>}
                                <li className="nav-item">
                                    <NavLink className= "nav-link style-nav-link" to="/inventario" onClick={toggleMenu}>INVENTARIO </NavLink>
                                </li>
                                { userRole !== 0 && <li className="nav-item">
                                    <NavLink className="nav-link style-nav-link" to="/total-ventas" onClick={toggleMenu}>TOTAL VENTAS </NavLink>
                                </li>}
                                { userRole !== 0 && <li className="nav-item">
                                    <NavLink className="nav-link style-nav-link" to="/egresos" onClick={toggleMenu}>GASTOS </NavLink>
                                </li>}
                                <li className="nav-item">
                                    <NavLink className="nav-link style-nav-link" to="/cierre-caja" onClick={toggleMenu}>CIERRE CAJA </NavLink>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link logout style-nav-link" >
                                        <i className="fa-solid fa-right-from-bracket" onClick={logout}></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            )
        }
    }
      
    return showNavBar();
}

export default NavBar;