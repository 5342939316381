import React, { createContext, useState } from 'react';

const AuthorizationContext = createContext();

const AuthorizationProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(0);

  const setUserAuthorization = (role) => {
    setUserRole(role);
  };

  return (
    <AuthorizationContext.Provider value={{ userRole, setUserAuthorization }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export { AuthorizationContext, AuthorizationProvider };