import React, { useContext } from "react";
import { useState } from "react";
import logo from "../../assets/logo-bendi.png";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {ENDPOINT} from "../../constants/endpointConstants"
import { Modal } from "react-bootstrap";
import { AuthorizationContext } from '../../AuthorizationProvider'



const LoginPage = ({bringUser}) => {
    const { setUserAuthorization } = useContext(AuthorizationContext);

    const[user, setUser] = useState('');
    const[password, setPassword] = useState('');
    const[loginError, setLoginError] = useState(false);
    const[loading, setLoading] = useState(false);

    const navigate = useNavigate ();

    const userLogin = async (e) => {
        e.preventDefault();
        let addUser ={
            userId:user,
            password: password,
        }
        setLoading(true); 
        try{
            let submitUser = await axios.post(ENDPOINT.POST_LOGIN,addUser); 
            const {token, name, userId, role} = submitUser.data;
            setUserAuthorization(role);
            setLoading(false)
            localStorage.setItem('que-bendicion-user', JSON.stringify({
                token,
                name,
                userId
            }))
            bringUser(name)
            navigate("caja")
        } catch(error){
            setLoginError(true)
            setLoading(false)
        }
    } 

    const userChange = (event) => {
        setUser(event.target.value);
    }

    const userPassword = (event) => {
        setPassword(event.target.value);
    }

    const closeModal = () => {
        setLoginError(false)
    }

    const buttonContent = () => {
        if(loading === false){
            return "Ingresar";
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const isButtonDisabled = () => {
        if(user === "" || password === ""){
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className='login col-10 col-md-4'>
                    <div className="w-100 d-flex flex-column align-items-center">
                        <img src={logo}/>
                    </div>
                    <form onSubmit={userLogin}>
                        <div className="row justify-content-center">
                            <div className=" col-8 mb-3">
                                <input type="text" placeholder="Usuario" className="form-control" onChange={userChange}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-8 mb-3">
                                <input type="password" placeholder="Contraseña" className="form-control" onChange={userPassword}/>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary btn-sm loader" disabled={isButtonDisabled()}>
                                {buttonContent()}
                            </button>
                        </div>
                    </form>
                </div> 
            </div>
            <Modal show={loginError} onHide={closeModal}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title >Error de ingreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>Usuario o contraseña no encontrados</Modal.Body>
            </Modal>
            
        </div>
        
        )
}

export default LoginPage;