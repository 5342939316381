export const PAYMENT_METHOD = [
    {
        id: 0,
        name:"Efectivo"
    },
    {
        id: 1,
        name: "Transferencia"
    }
]

export const EXPENSE_CATEGORIES = [
    {
        id: 0,
        name: 'Nomina'
    },
    {
        id: 1,
        name: 'Insumos'
    },
    {
        id: 2,
        name: 'Desechables'
    },
    {
        id: 3,
        name: 'Aseo'
    },
    {
        id: 4,
        name: 'Implementos de cocina'
    },
    {
        id: 5,
        name: 'Publicidad'
    },
    {
        id: 6,
        name: 'Servicios Publicos'
    },
    {
        id: 7,
        name: 'Internet'
    },
    {
        id: 8,
        name: 'Arriendo'
    },
    {
        id: 9,
        name: 'Extraordinario'
    },
]

