import { useState } from "react";
import Table from "../../components/Table";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { ENDPOINT } from "../../constants/endpointConstants";
import axios from "axios";
import { useEffect } from "react";
import { getToken } from "../../utils/utils";

const MealsPage = () => {
    const defaultSupplies = [
        {
            supplyId: '',
            name: '',
            amount: 0
        }
    ]


    const[newProduct, setNewProduct] = useState(false);
    const[deleteProducts, setDeleteProducts] = useState(false)
    const[items, setItems] = useState([]);
    const[nameProduct, setNameProduct] = useState('');
    const[loading, setLoading] = useState(false);
    const[salePrice, setSalePrice] = useState('');
    const[isUpdateModal, setIsUpdateModal] = useState(false);
    const[productId, setProductId] = useState('');
    const[isLoadingInventory, setIsLoadingInventory] = useState(false);
    const[suppliesList, setSuppliesList] = useState([]);
    const[supplies, setSupplies] = useState(defaultSupplies);

    



    const bringInformation = async () => {
        const token = getToken();
        setIsLoadingInventory(true);
        const bringProducts = await axios.get(ENDPOINT.GET_PRODUCTS, token);
        setItems(bringProducts.data);
        setIsLoadingInventory(false);
    }
    useEffect( () => {
        bringInformation();
        getSupplies();
    } ,[]) 

    const getSupplies = async () => {
        const token = getToken();
        const response = await axios.get(ENDPOINT.GET_SUPPLIES, token);
        setSuppliesList(response.data)
    }

    const addNewProduct = async () => {
        let addProduct = {
            name: nameProduct,
            salePrice: salePrice,
            supplies
        }
        const token = getToken()
        setLoading(true)
        try {
            const submitProduct = await axios.post(ENDPOINT.POST_NEW_PRODUCT,addProduct,token)
            setLoading(false)
            let newItems = items;
            newItems.push(submitProduct.data)
            setItems(newItems.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)))
            modalClose();
            resetProductFormValues();
        } catch (error) {
            setLoading(false)
        }
    } 

    const updateProduct = async () => {
        let update = {
            name: nameProduct,
            salePrice: salePrice,
            productId: productId,
            supplies
        }
        const token = getToken()
        setLoading(true)
        try {
            const submitUpdateProduct = await axios.put(ENDPOINT.PUT_UPDATE_PRODUCT,update,token)
            setLoading(false)
            const productIndex = items.findIndex( (item) => {
                return item.productId === productId
            })
            items[productIndex] = submitUpdateProduct.data
            setItems(items)
            modalClose();
            resetProductFormValues();
        } catch (error) {
            setLoading(false)
            
        }
    }

    const deleteProductModal = async () => {
        let putOffProduct = {
            productId: productId,
        }
        const token = getToken()
        token.data = putOffProduct
        setLoading(true)
        try {
            const submitDeleteProduct = await axios.delete(ENDPOINT.DEL_DELETE_PRODUCT,token)
            setLoading(false)
            const itemsUpdated = items.filter( (item) => {
                return item.productId !== productId
            })
            setItems(itemsUpdated)
            modalCloseDelete()
        } catch (error) {
            setLoading(false)
        }
    }


    const nameChange = (event) => {
        setNameProduct(event.target.value)
    }

    const salePriceChange = (event) => {
        setSalePrice(parseInt(event.target.value))
    }

    const showModalDelete = () => {
        setDeleteProducts(true)
    }

    const showModal = () => {
        setNewProduct(true)
    }

    const modalCloseDelete = () => {
        setDeleteProducts(false)
    }
    
    const modalClose = () => {
        setNewProduct(false)
        setSupplies(defaultSupplies);
    }
    const spinnerDelete = () => {
        if(loading === false){
            return "SI";
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const buttonContent = () => {
        if(loading === false){
            return UpdateModal();
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const isButtonDisabled = () => {
        if( nameProduct === "" ||
            isValidInputNumber(salePrice) ||
            !supplies.length ||
            supplies.some(supply => !supply.supplyId || !supply.amount)){
            return true
        } else {
            return false
        }
    }

    const isValidInputNumber =  (value) => {
        return !value || isNaN(value);
    }

    const resetProductFormValues = () => {
        setNameProduct('');
        setSalePrice('');
    }    

    const editProduct = (product) => {
        setNameProduct(product.name)
        setSalePrice(product.salePrice)
        setSupplies(product.supplies || defaultSupplies)
        setProductId(product.productId)
        setIsUpdateModal(true)
        showModal()
    }

    const deleteProduct = (product) => {
        setProductId(product.productId)
        showModalDelete()
    }

    const UpdateModal = () => {
        if(isUpdateModal === true){
            return (
                "ACTUALIZAR"
            ) 
        } else {
            return (
                "AGREGAR"
            )
        }
    }

    const modalOnClick = () => {
        if(isUpdateModal === true){
            updateProduct()
        } else {
            addNewProduct()
        }
    }

    const showModalNewProduct = () => {
        resetProductFormValues()
        setIsUpdateModal(false)
        showModal()
    }

    const inventoryColumns = [
        {
            name: "Nombre Producto",
            key: "name",
            type: "text"
        },
        {
            name: "Precio venta",
            key: "salePrice",
            type: "currency"
        },
        {
            name: "Acciones",
            key:"action",
            type: "actions",
            buttons: [
                {
                    iconClass: "fa-regular fa-pen-to-square",
                    action: editProduct 
                },
                {
                    iconClass: "fa-solid fa-trash-can",
                    action: deleteProduct
                }
            ]
        }

    ];

    const removeSupply = (index) => {
        let newSupply = [... supplies];
        newSupply.splice(index, 1);
        setSupplies(newSupply);
    }

    const supplyOnChange = ($event, index) => {
        const supplyId = $event.target.value;
        if (supplies.some(supply => supply.supplyId === supplyId)) {
            return;
        }
        let newSupplies = [... supplies];
        const supplySelected = suppliesList.find(supply => supply.supplyId === supplyId)
        newSupplies[index] = {
            supplyId,
            name: supplySelected.name,
            amount: 1,
        }
        setSupplies(newSupplies);
    }

    const supplyAmountOnChange = ($event, index) => {
        let newSupplies = [... supplies];
        const supplyId = newSupplies[index].supplyId;
        newSupplies[index] = {
            ... newSupplies[index],
            amount: parseInt($event.target.value)
        }
        setSupplies(newSupplies);
    }

    const addNewSupply = () => {
        setSupplies([
            ... supplies,
            {
                supplyId: '',
                name: '',
                amount: 0,
            }
        ])
    }

    const isRemoveButtonDisabled = () => supplies.length && (supplies.length - 1)



    return(
        <div>
            <div className ="mx-4">
                <h1 className="style-title-page mt-4">Platos</h1>
                <button type="button" className="btn btn-primary btn-sm mt-5" data-bs-target="#modal" onClick={showModalNewProduct}> <i className="fa-solid fa-plus"></i> Agregar plato </button>
                <Table columnNames={inventoryColumns} items={items} fixSize={'t-responsive-large'} isLoading={isLoadingInventory}/>
            </div>
            <Modal show={newProduct} onHide={modalClose}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title className="text-modal"> {UpdateModal()} PRODUCTO </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="col-form-label">Nombre Plato</label>
                            <input type="text" className="form-control" value={nameProduct} onChange={nameChange}/>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Precio</label>
                            <input type="number" className="form-control" id="sale-price" value={salePrice} onChange={salePriceChange}/>
                        </div>
                    </div>
                    <div className="row title-bar py-2 mt-4 mb-3">
                        <h5>Ingredientes</h5>
                    </div>
                    <div className="row">
                        <div className="col-9 input-title">Ingrediente</div>
                        <div className="col-2 input-title text-ellipsis">Cantidad</div>
                    </div>
                    {
                        supplies.map((supply, index) =>
                            <div className="row align-items-center mt-2" key={index}>
                                <div className={`col-9`}>
                                    <select className="form-select cashier-select" value={supply.supplyId} onChange={(e) => supplyOnChange(e, index)}>
                                        <option value="" disabled>Seleccione un ingrediente</option>
                                        {
                                            suppliesList.length ?
                                            suppliesList.map((supplyOption, i) => <option value={supplyOption.supplyId} key={i}>{supplyOption.name}</option>):
                                            null
                                        }
                                    </select>
                                </div>
                                <div className="col-2">
                                    <input type="number" className={`form-control`} placeholder="Cantidad" value={supply.amount} onChange={(e) => supplyAmountOnChange(e, index)}/>
                                </div>
                                <div className="col-1">
                                    <button type="button" className="btn btn-table btn-sm" onClick={()=>removeSupply(index)} disabled={!isRemoveButtonDisabled()}> <i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>
                        )
                    }
                    <div className="row mt-5">
                        <div className="col-8 col-md-9">
                            <button type="button" className="btn btn-table btn-sm" onClick={addNewSupply}> <i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-6 col-md-5">
                            <button type="button" className="btn btn-primary btn-sm w-100 text-modal" onClick={modalOnClick} disabled={isButtonDisabled()}> {buttonContent()}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={deleteProducts} onHide={modalCloseDelete}>
                <Modal.Header closeButton className="style-modal-header ">
                    <Modal.Title className="text-modal"> ELIMINAR PRODUCTO </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <p className="text-center"> Estas seguro que deseas eliminar este producto? </p>
                    <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={deleteProductModal}> {spinnerDelete()} </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-primary btn-sm w-100 text-modal mt-3" onClick={modalCloseDelete}> NO </button>
                            </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default MealsPage;